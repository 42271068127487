@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~bootstrap/dist/css/bootstrap.min.css";

$sizes: 12;
$tags: center, space-between, space-around, flex-start, flex-end;

@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 0.25rem;
    .m-#{$i} {
      margin: $margin !important;
    }
    .ml-#{$i} {
      margin-left: $margin !important;
    }
    .mr-#{$i} {
      margin-right: $margin !important;
    }
    .mt-#{$i} {
      margin-top: $margin !important;
    }
    .mb-#{$i} {
      margin-bottom: $margin !important;
    }
    .mx-#{$i} {
      margin-left: $margin !important;
      margin-right: $margin !important;
    }
    .my-#{$i} {
      margin-top: $margin !important;
      margin-bottom: $margin !important;
    }
  }
}

@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;
    .p-#{$i} {
      padding: $padding !important;
    }
    .pl-#{$i} {
      padding-left: $padding !important;
    }
    .pr-#{$i} {
      padding-right: $padding !important;
    }
    .pt-#{$i} {
      padding-top: $padding !important;
    }
    .pb-#{$i} {
      padding-bottom: $padding !important;
    }
    .px-#{$i} {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }
    .py-#{$i} {
      padding-top: $padding !important;
      padding-bottom: $padding !important;
    }
  }
}

@mixin width-classes {
  @for $i from 1 through 100 {
    .w-#{$i} {
      width: $i * 1%;
    }
    .vw-#{$i} {
      width: $i * 1vw;
    }
    .min-vw-#{$i} {
      min-width: $i * 1vw;
    }
    .max-vw-#{$i} {
      max-width: $i * 1vw;
    }
  }
}

@mixin height-classes {
  @for $i from 1 through 100 {
    .h-#{$i} {
      height: $i * 1%;
    }
    .vh-#{$i} {
      height: $i * 1vh;
    }
    .min-vh-#{$i} {
      min-height: $i * 1vh;
    }
    .max-vh-#{$i} {
      max-height: $i * 1vh;
    }
  }
}

@mixin font-classes {
  @for $i from 1 through 100 {
    .f-#{$i} {
      font-size: $i * 1px !important;
    }
  }
}

@mixin justify-properties($tag) {
  justify-content: $tag;
}

@mixin align-properties($tag) {
  align-items: $tag;
}

@each $tag in $tags {
  .justify-#{$tag} {
    @include justify-properties($tag);
  }
}

@each $tag in $tags {
  .align-#{$tag} {
    @include align-properties($tag);
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: auto;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.cursor-pointer {
  cursor: pointer;
}

.card-rounded {
  border-radius: 15px !important;
}

#contact-field {
  color: #fff;
}

#text-footer {
  color: #fff;
  margin-top: 0.5em;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px lightgrey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbe0f9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dbe0f9;
}

@media (max-width: 1024px) {
  #content {
    padding-top: 12vh;
    padding-bottom: 12vh;
  }
}

@include font-classes;
@include margin-classes;
@include padding-classes;
@include width-classes;
@include height-classes;
